import './Spinner.scss';
import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export function Spinner() {
  return (
    <div className="spinner_wrapper">
      <span className="spinner" />
    </div>
  );
}
