import firebase from 'firebase';
import { Formik } from 'formik';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import * as Yup from 'yup';
import { defaultLanguage } from '../../prismic-configuration';
import PersonalDataForm from '../components/Form-components/PersonalDataForm/BasicPersonalDataForm-component';
import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import { Spinner } from '../components/Spinner/Spinner';
import { linkResolver } from '../utils/LinkResolver';

const isBrowser = typeof window !== 'undefined';

const EditMCMForms = ({ location, data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const [userUnderReview, setUserUnderReview] = useState('');
  const [generalComments, setGeneralComments] = useState('');
  const [hasCompletedPdps, setHasCompletedPdps] = useState('');
  const [hasCompletedPdpsComments, setHasCompletedPdpsComments] = useState('');

  //get user id from params and users profile
  useEffect(() => {
    let isMounted = true;
    const params = queryString.parse(location.search);

    if (params.userId && isBrowser && isMounted) {
      setUserId(params.userId);
      try {
        firebase
          .firestore()
          .collection('usersCollection')
          .onSnapshot(snapshot => {
            const listUsers = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
            }));
            const userUnderReview = listUsers.filter(user => user.id === params.userId)[0];
            setUserUnderReview(userUnderReview);
            setGeneralComments(userUnderReview?.secretaryGeneralComments);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      alert('necessary data is missing');
    }
    return () => {
      if (isBrowser && isMounted) {
        try {
          firebase
            .firestore()
            .collection('usersCollection')
            .onSnapshot(snapshot => {
              const listUsers = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
              }));
            });
        } catch (error) {
          console.log(error);
        }
        isMounted = false;
      }
    };
  }, [location.search]);

  if (!data) return null;

  const pageContent = data.prismicEditMcmForms;
  const page = pageContent.data || {};

  const { lang, type, url } = pageContent;
  const alternateLanguages = pageContent.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };

  const localLinkResolver = lang === defaultLanguage ? '' : `/${lang}`;
  const topMenu = data.prismicMenuLink || {};
  const firstFormVars = data.prismicMcmReviewForm?.data?.body[0]?.items;
  const secondFormVars = data.prismicMcmReviewForm?.data?.body[1]?.items;

  const form1InitialValues = userUnderReview.mcm1ReviewProcess,
    form2InitialValues = userUnderReview.mcm2ReviewProcess,
    form3InitialValues = userUnderReview.mcm3ReviewProcess;

  const mcmFormDataArray = [form1InitialValues, form2InitialValues, form3InitialValues];

  const handleUpdateCustomForm = () => {
    try {
      firebase
        .firestore()
        .collection('usersCollection')
        .doc(userUnderReview?.id)
        .update({
          secretaryGeneralComments: generalComments, //comments from secretary related to the reviews from mcm
          has_completed_pdps: hasCompletedPdps || 'Please select',
          has_completed_pdps_comments: hasCompletedPdpsComments || ' ',
        });
    } catch (error) {
      console.log(error);
    }
    alert('You have Updated General comments');
  };

  const firstFormValidationSchema = Yup.object().shape({
    mcm_name: Yup.string().required('Required'),
    applicantID: Yup.string().required('Required'),
    is_this_the_first_review: Yup.string().required('Required'),
    advances_in_the_personal_development: Yup.string(),
    advances_in_the_personal_development_comments: Yup.string(),
    validating_the_applicant_academic_position: Yup.string().required('Required'),
    validating_the_applicant_academic_position_comments: Yup.string(),
    validating_the_knowledge_domain: Yup.string().required('Required'),
    validating_the_knowledge_domain_comments: Yup.string(),
    knowledge_domain_relevant_for_the_profession: Yup.string().required('Required'),
    knowledge_domain_relevant_for_the_profession_comments: Yup.string(),
    evidence_of_the_highest_academic_degree_provided: Yup.string().required('Required'),
    evidence_of_the_highest_academic_degree_provided_comments: Yup.string(),
    evidencing_the_overall_pedagogical_experience: Yup.string().required('Required'),
    evidencing_the_overall_pedagogical_experience_comments: Yup.string(),
    are_the_courses_lectured: Yup.string().required('Required'),
    are_the_courses_lectured_comments: Yup.string(),
    is_the_membership_in_pedagogical_committee_relevant: Yup.string().required('Required'),
    is_the_membership_in_pedagogical_committee_relevant_comments: Yup.string(),
    are_the_trainings_relevant: Yup.string().required('Required'),
    are_the_trainings_relevant_comments: Yup.string(),
    are_the_conferences_relevant: Yup.string().required('Required'),
    are_the_conferences_relevant_comments: Yup.string(),
    are_the_pedagogical_experience_relevant: Yup.string().required('Required'),
    are_the_pedagogical_experience_relevant_comments: Yup.string(),
    are_the_internship_experience_relevant: Yup.string().required('Required'),
    are_the_internship_experience_relevant_comments: Yup.string(),
    are_the_membership_in_associations_relevant: Yup.string().required('Required'),
    are_the_membership_in_associations_relevant_comments: Yup.string(),
    is_the_research_evidenced_is_relevant: Yup.string().required('Required'),
    is_the_research_evidenced_is_relevant_comments: Yup.string(),
    are_the_awards_relevant: Yup.string().required('Required'),
    are_the_awards_relevant_comments: Yup.string(),
    is_the_motivation_relevant: Yup.string().required('Required'),
    is_the_motivation_relevant_comments: Yup.string(),
  });

  const secondFormValidationSchema = Yup.object().shape({
    innovations_in_engineering_pedagogy_evidenced: Yup.string().required('Required'),
    innovations_in_engineering_pedagogy_strengths: Yup.string(),
    innovations_in_engineering_pedagogy_suggestions: Yup.string(),
    time_management_evidenced: Yup.string().required('Required'),
    time_management_evidenced_strengths: Yup.string(),
    time_management_evidenced_suggestions: Yup.string(),
    effective_interaction_evidenced: Yup.string().required('Required'),
    effective_interaction_evidenced_strengths: Yup.string(),
    effective_interaction_evidenced_suggestions: Yup.string(),
    enhancement_of_learning_interactivity: Yup.string().required('Required'),
    enhancement_of_learning_interactivity_strengths: Yup.string(),
    enhancement_of_learning_interactivity_suggestions: Yup.string(),
    systems_analysis_in_education: Yup.string().required('Required'),
    systems_analysis_in_education_strengths: Yup.string(),
    systems_analysis_in_education_suggestions: Yup.string(),
    pedagogical_psychology_and_communication: Yup.string().required('Required'),
    pedagogical_psychology_and_communication_strengths: Yup.string(),
    pedagogical_psychology_and_communication_suggestions: Yup.string(),
    interaction_with_stakeholders: Yup.string().required('Required'),
    interaction_with_stakeholders_strengths: Yup.string(),
    interaction_with_stakeholders_suggestions: Yup.string(),
    sustainable_development: Yup.string().required('Required'),
    sustainable_development_strengths: Yup.string(),
    sustainable_development_suggestions: Yup.string(),
    digital_education: Yup.string().required('Required'),
    digital_education_strengths: Yup.string(),
    digital_education_suggestions: Yup.string(),
    problem_based_project_based_and_practice_oriented_learning: Yup.string().required('Required'),
    problem_based_project_based_and_practice_oriented_learning_strengths: Yup.string(),
    problem_based_project_based_and_practice_oriented_learning_suggestions: Yup.string(),
    learning_outcomes_assessment: Yup.string().required('Required'),
    learning_outcomes_assessment_strengths: Yup.string(),
    learning_outcomes_assessment_suggestions: Yup.string(),
    course_design: Yup.string().required('Required'),
    course_design_strengths: Yup.string(),
    course_design_suggestions: Yup.string(),
    engineering_innovation_process: Yup.string().required('Required'),
    engineering_innovation_process_strengths: Yup.string(),
    engineering_innovation_process_suggestions: Yup.string(),
    lifelong_learning: Yup.string().required('Required'),
    lifelong_learning_strengths: Yup.string(),
    lifelong_learning_suggestions: Yup.string(),
    facebook_link: Yup.string(),
    linkedin_link: Yup.string(),
    instagram_link: Yup.string(),
    twitter_link: Yup.string(),
    personal_website_link: Yup.string(),
    comments_to_the_monitoring_committee_chair: Yup.string(),
  });

  return (
    <Layout topMenu={topMenu?.data} activeDocMeta={activeDoc}>
      <Seo title={page?.title?.text} lang={lang} />
      {isLoading ? (
        <Spinner />
      ) : (
        <Container className="shadow mt-5">
          <h1 className="page-title">Edit mcm reviews in user profile</h1>
          <p>
            *to update data in users profile, you have to click on every {`<SAVE>`} button. Two on
            each form.
          </p>
          <p>**When you make all necessary changes, you may close the window.</p>
          <div className="final-step-approval-proccess border border-3 shadow">
            {mcmFormDataArray.map((mcmForm, key) => {
              const saveOnSubmit = async values => {
                if (key === 0) {
                  try {
                    await firebase
                      .firestore()
                      .collection('usersCollection')
                      .doc(userId)
                      .update({
                        mcm1ReviewProcess: {
                          mcm_name: values.mcm_name,
                          applicantID: values.applicantID,
                          is_this_the_first_review: values.is_this_the_first_review || '',
                          advances_in_the_personal_development:
                            values.advances_in_the_personal_development || '',
                          advances_in_the_personal_development_comments:
                            values.advances_in_the_personal_development_comments || '',
                          validating_the_applicant_academic_position:
                            values.validating_the_applicant_academic_position || '',
                          validating_the_applicant_academic_position_comments:
                            values.validating_the_applicant_academic_position_comments || '',
                          validating_the_knowledge_domain:
                            values.validating_the_knowledge_domain || '',
                          validating_the_knowledge_domain_comments:
                            values.validating_the_knowledge_domain_comments || '',
                          knowledge_domain_relevant_for_the_profession:
                            values.knowledge_domain_relevant_for_the_profession || '',
                          knowledge_domain_relevant_for_the_profession_comments:
                            values.knowledge_domain_relevant_for_the_profession_comments || '',
                          evidence_of_the_highest_academic_degree_provided:
                            values.evidence_of_the_highest_academic_degree_provided || '',
                          evidence_of_the_highest_academic_degree_provided_comments:
                            values.evidence_of_the_highest_academic_degree_provided_comments || '',
                          evidencing_the_overall_pedagogical_experience:
                            values.evidencing_the_overall_pedagogical_experience || '',
                          evidencing_the_overall_pedagogical_experience_comments:
                            values.evidencing_the_overall_pedagogical_experience_comments || '',
                          are_the_courses_lectured: values.are_the_courses_lectured || '',
                          are_the_courses_lectured_comments:
                            values.are_the_courses_lectured_comments || '',
                          is_the_membership_in_pedagogical_committee_relevant:
                            values.is_the_membership_in_pedagogical_committee_relevant || '',
                          is_the_membership_in_pedagogical_committee_relevant_comments:
                            values.is_the_membership_in_pedagogical_committee_relevant_comments ||
                            '',
                          are_the_trainings_relevant: values.are_the_trainings_relevant || '',
                          are_the_trainings_relevant_comments:
                            values.are_the_trainings_relevant_comments || '',
                          are_the_conferences_relevant: values.are_the_conferences_relevant || '',
                          are_the_conferences_relevant_comments:
                            values.are_the_conferences_relevant_comments || '',
                          are_the_pedagogical_experience_relevant:
                            values.are_the_pedagogical_experience_relevant || '',
                          are_the_pedagogical_experience_relevant_comments:
                            values.are_the_pedagogical_experience_relevant_comments || '',
                          are_the_internship_experience_relevant:
                            values.are_the_internship_experience_relevant || '',
                          are_the_internship_experience_relevant_comments:
                            values.are_the_internship_experience_relevant_comments || '',
                          are_the_membership_in_associations_relevant:
                            values.are_the_membership_in_associations_relevant || '',
                          are_the_membership_in_associations_relevant_comments:
                            values.are_the_membership_in_associations_relevant_comments || '',
                          is_the_research_evidenced_is_relevant:
                            values.is_the_research_evidenced_is_relevant || '',
                          is_the_research_evidenced_is_relevant_comments:
                            values.is_the_research_evidenced_is_relevant_comments || '',
                          are_the_awards_relevant: values.are_the_awards_relevant || '',
                          are_the_awards_relevant_comments:
                            values.are_the_awards_relevant_comments || '',
                          is_the_motivation_relevant: values.is_the_motivation_relevant || '',
                          is_the_motivation_relevant_comments:
                            values.is_the_motivation_relevant_comments || '',
                          innovations_in_engineering_pedagogy_evidenced:
                            values.innovations_in_engineering_pedagogy_evidenced || '',
                          innovations_in_engineering_pedagogy_strengths:
                            values.innovations_in_engineering_pedagogy_strengths || '',
                          innovations_in_engineering_pedagogy_suggestions:
                            values.innovations_in_engineering_pedagogy_suggestions || '',
                          time_management_evidenced: values.time_management_evidenced || '',
                          time_management_evidenced_strengths:
                            values.time_management_evidenced_strengths || '',
                          time_management_evidenced_suggestions:
                            values.time_management_evidenced_suggestions || '',
                          effective_interaction_evidenced:
                            values.effective_interaction_evidenced || '',
                          effective_interaction_evidenced_strengths:
                            values.effective_interaction_evidenced_strengths || '',
                          effective_interaction_evidenced_suggestions:
                            values.effective_interaction_evidenced_suggestions || '',
                          enhancement_of_learning_interactivity:
                            values.enhancement_of_learning_interactivity || '',
                          enhancement_of_learning_interactivity_strengths:
                            values.enhancement_of_learning_interactivity_strengths || '',
                          enhancement_of_learning_interactivity_suggestions:
                            values.enhancement_of_learning_interactivity_suggestions || '',
                          systems_analysis_in_education: values.systems_analysis_in_education || '',
                          systems_analysis_in_education_strengths:
                            values.systems_analysis_in_education_strengths || '',
                          systems_analysis_in_education_suggestions:
                            values.systems_analysis_in_education_suggestions || '',
                          pedagogical_psychology_and_communication:
                            values.pedagogical_psychology_and_communication || '',
                          pedagogical_psychology_and_communication_strengths:
                            values.pedagogical_psychology_and_communication_strengths || '',
                          pedagogical_psychology_and_communication_suggestions:
                            values.pedagogical_psychology_and_communication_suggestions || '',
                          interaction_with_stakeholders: values.interaction_with_stakeholders || '',
                          interaction_with_stakeholders_strengths:
                            values.interaction_with_stakeholders_strengths || '',
                          interaction_with_stakeholders_suggestions:
                            values.interaction_with_stakeholders_suggestions || '',
                          sustainable_development: values.sustainable_development || '',
                          sustainable_development_strengths:
                            values.sustainable_development_strengths || '',
                          sustainable_development_suggestions:
                            values.sustainable_development_suggestions || '',
                          digital_education: values.digital_education || '',
                          digital_education_strengths: values.digital_education_strengths || '',
                          digital_education_suggestions: values.digital_education_suggestions || '',
                          problem_based_project_based_and_practice_oriented_learning:
                            values.problem_based_project_based_and_practice_oriented_learning || '',
                          problem_based_project_based_and_practice_oriented_learning_strengths:
                            values.problem_based_project_based_and_practice_oriented_learning_strengths ||
                            '',
                          problem_based_project_based_and_practice_oriented_learning_suggestions:
                            values.problem_based_project_based_and_practice_oriented_learning_suggestions ||
                            '',
                          learning_outcomes_assessment: values.learning_outcomes_assessment || '',
                          learning_outcomes_assessment_strengths:
                            values.learning_outcomes_assessment_strengths || '',
                          learning_outcomes_assessment_suggestions:
                            values.learning_outcomes_assessment_suggestions || '',
                          course_design: values.course_design || '',
                          course_design_strengths: values.course_design_strengths || '',
                          course_design_suggestions: values.course_design_suggestions || '',
                          engineering_innovation_process:
                            values.engineering_innovation_process || '',
                          engineering_innovation_process_strengths:
                            values.engineering_innovation_process_strengths || '',
                          engineering_innovation_process_suggestions:
                            values.engineering_innovation_process_suggestions || '',
                          lifelong_learning: values.lifelong_learning || '',
                          lifelong_learning_strengths: values.lifelong_learning_strengths || '',
                          lifelong_learning_suggestions: values.lifelong_learning_suggestions || '',
                          facebook_link: values.facebook_link || '',
                          linkedin_link: values.linkedin_link || '',
                          instagram_link: values.instagram_link || '',
                          twitter_link: values.twitter_link || '',
                          personal_website_link: values.personal_website_link || '',
                          comments_to_the_monitoring_committee_chair:
                            values.comments_to_the_monitoring_committee_chair || '',
                        },
                      });
                  } catch (error) {
                    console.log(error);
                  }
                } else if (key === 1) {
                  try {
                    await firebase
                      .firestore()
                      .collection('usersCollection')
                      .doc(userId)
                      .update({
                        mcm2ReviewProcess: {
                          mcm_name: values.mcm_name,
                          applicantID: values.applicantID,
                          is_this_the_first_review: values.is_this_the_first_review || '',
                          advances_in_the_personal_development:
                            values.advances_in_the_personal_development || '',
                          advances_in_the_personal_development_comments:
                            values.advances_in_the_personal_development_comments || '',
                          validating_the_applicant_academic_position:
                            values.validating_the_applicant_academic_position || '',
                          validating_the_applicant_academic_position_comments:
                            values.validating_the_applicant_academic_position_comments || '',
                          validating_the_knowledge_domain:
                            values.validating_the_knowledge_domain || '',
                          validating_the_knowledge_domain_comments:
                            values.validating_the_knowledge_domain_comments || '',
                          knowledge_domain_relevant_for_the_profession:
                            values.knowledge_domain_relevant_for_the_profession || '',
                          knowledge_domain_relevant_for_the_profession_comments:
                            values.knowledge_domain_relevant_for_the_profession_comments || '',
                          evidence_of_the_highest_academic_degree_provided:
                            values.evidence_of_the_highest_academic_degree_provided || '',
                          evidence_of_the_highest_academic_degree_provided_comments:
                            values.evidence_of_the_highest_academic_degree_provided_comments || '',
                          evidencing_the_overall_pedagogical_experience:
                            values.evidencing_the_overall_pedagogical_experience || '',
                          evidencing_the_overall_pedagogical_experience_comments:
                            values.evidencing_the_overall_pedagogical_experience_comments || '',
                          are_the_courses_lectured: values.are_the_courses_lectured || '',
                          are_the_courses_lectured_comments:
                            values.are_the_courses_lectured_comments || '',
                          is_the_membership_in_pedagogical_committee_relevant:
                            values.is_the_membership_in_pedagogical_committee_relevant || '',
                          is_the_membership_in_pedagogical_committee_relevant_comments:
                            values.is_the_membership_in_pedagogical_committee_relevant_comments ||
                            '',
                          are_the_trainings_relevant: values.are_the_trainings_relevant || '',
                          are_the_trainings_relevant_comments:
                            values.are_the_trainings_relevant_comments || '',
                          are_the_conferences_relevant: values.are_the_conferences_relevant || '',
                          are_the_conferences_relevant_comments:
                            values.are_the_conferences_relevant_comments || '',
                          are_the_pedagogical_experience_relevant:
                            values.are_the_pedagogical_experience_relevant || '',
                          are_the_pedagogical_experience_relevant_comments:
                            values.are_the_pedagogical_experience_relevant_comments || '',
                          are_the_internship_experience_relevant:
                            values.are_the_internship_experience_relevant || '',
                          are_the_internship_experience_relevant_comments:
                            values.are_the_internship_experience_relevant_comments || '',
                          are_the_membership_in_associations_relevant:
                            values.are_the_membership_in_associations_relevant || '',
                          are_the_membership_in_associations_relevant_comments:
                            values.are_the_membership_in_associations_relevant_comments || '',
                          is_the_research_evidenced_is_relevant:
                            values.is_the_research_evidenced_is_relevant || '',
                          is_the_research_evidenced_is_relevant_comments:
                            values.is_the_research_evidenced_is_relevant_comments || '',
                          are_the_awards_relevant: values.are_the_awards_relevant || '',
                          are_the_awards_relevant_comments:
                            values.are_the_awards_relevant_comments || '',
                          is_the_motivation_relevant: values.is_the_motivation_relevant || '',
                          is_the_motivation_relevant_comments:
                            values.is_the_motivation_relevant_comments || '',
                          innovations_in_engineering_pedagogy_evidenced:
                            values.innovations_in_engineering_pedagogy_evidenced || '',
                          innovations_in_engineering_pedagogy_strengths:
                            values.innovations_in_engineering_pedagogy_strengths || '',
                          innovations_in_engineering_pedagogy_suggestions:
                            values.innovations_in_engineering_pedagogy_suggestions || '',
                          time_management_evidenced: values.time_management_evidenced || '',
                          time_management_evidenced_strengths:
                            values.time_management_evidenced_strengths || '',
                          time_management_evidenced_suggestions:
                            values.time_management_evidenced_suggestions || '',
                          effective_interaction_evidenced:
                            values.effective_interaction_evidenced || '',
                          effective_interaction_evidenced_strengths:
                            values.effective_interaction_evidenced_strengths || '',
                          effective_interaction_evidenced_suggestions:
                            values.effective_interaction_evidenced_suggestions || '',
                          enhancement_of_learning_interactivity:
                            values.enhancement_of_learning_interactivity || '',
                          enhancement_of_learning_interactivity_strengths:
                            values.enhancement_of_learning_interactivity_strengths || '',
                          enhancement_of_learning_interactivity_suggestions:
                            values.enhancement_of_learning_interactivity_suggestions || '',
                          systems_analysis_in_education: values.systems_analysis_in_education || '',
                          systems_analysis_in_education_strengths:
                            values.systems_analysis_in_education_strengths || '',
                          systems_analysis_in_education_suggestions:
                            values.systems_analysis_in_education_suggestions || '',
                          pedagogical_psychology_and_communication:
                            values.pedagogical_psychology_and_communication || '',
                          pedagogical_psychology_and_communication_strengths:
                            values.pedagogical_psychology_and_communication_strengths || '',
                          pedagogical_psychology_and_communication_suggestions:
                            values.pedagogical_psychology_and_communication_suggestions || '',
                          interaction_with_stakeholders: values.interaction_with_stakeholders || '',
                          interaction_with_stakeholders_strengths:
                            values.interaction_with_stakeholders_strengths || '',
                          interaction_with_stakeholders_suggestions:
                            values.interaction_with_stakeholders_suggestions || '',
                          sustainable_development: values.sustainable_development || '',
                          sustainable_development_strengths:
                            values.sustainable_development_strengths || '',
                          sustainable_development_suggestions:
                            values.sustainable_development_suggestions || '',
                          digital_education: values.digital_education || '',
                          digital_education_strengths: values.digital_education_strengths || '',
                          digital_education_suggestions: values.digital_education_suggestions || '',
                          problem_based_project_based_and_practice_oriented_learning:
                            values.problem_based_project_based_and_practice_oriented_learning || '',
                          problem_based_project_based_and_practice_oriented_learning_strengths:
                            values.problem_based_project_based_and_practice_oriented_learning_strengths ||
                            '',
                          problem_based_project_based_and_practice_oriented_learning_suggestions:
                            values.problem_based_project_based_and_practice_oriented_learning_suggestions ||
                            '',
                          learning_outcomes_assessment: values.learning_outcomes_assessment || '',
                          learning_outcomes_assessment_strengths:
                            values.learning_outcomes_assessment_strengths || '',
                          learning_outcomes_assessment_suggestions:
                            values.learning_outcomes_assessment_suggestions || '',
                          course_design: values.course_design || '',
                          course_design_strengths: values.course_design_strengths || '',
                          course_design_suggestions: values.course_design_suggestions || '',
                          engineering_innovation_process:
                            values.engineering_innovation_process || '',
                          engineering_innovation_process_strengths:
                            values.engineering_innovation_process_strengths || '',
                          engineering_innovation_process_suggestions:
                            values.engineering_innovation_process_suggestions || '',
                          lifelong_learning: values.lifelong_learning || '',
                          lifelong_learning_strengths: values.lifelong_learning_strengths || '',
                          lifelong_learning_suggestions: values.lifelong_learning_suggestions || '',
                          facebook_link: values.facebook_link || '',
                          linkedin_link: values.linkedin_link || '',
                          instagram_link: values.instagram_link || '',
                          twitter_link: values.twitter_link || '',
                          personal_website_link: values.personal_website_link || '',
                          comments_to_the_monitoring_committee_chair:
                            values.comments_to_the_monitoring_committee_chair || '',
                        },
                      });
                  } catch (error) {
                    console.log(error);
                  }
                } else if (key === 2) {
                  try {
                    await firebase
                      .firestore()
                      .collection('usersCollection')
                      .doc(userId)
                      .update({
                        mcm3ReviewProcess: {
                          mcm_name: values.mcm_name,
                          applicantID: values.applicantID,
                          is_this_the_first_review: values.is_this_the_first_review || '',
                          advances_in_the_personal_development:
                            values.advances_in_the_personal_development || '',
                          advances_in_the_personal_development_comments:
                            values.advances_in_the_personal_development_comments || '',
                          validating_the_applicant_academic_position:
                            values.validating_the_applicant_academic_position || '',
                          validating_the_applicant_academic_position_comments:
                            values.validating_the_applicant_academic_position_comments || '',
                          validating_the_knowledge_domain:
                            values.validating_the_knowledge_domain || '',
                          validating_the_knowledge_domain_comments:
                            values.validating_the_knowledge_domain_comments || '',
                          knowledge_domain_relevant_for_the_profession:
                            values.knowledge_domain_relevant_for_the_profession || '',
                          knowledge_domain_relevant_for_the_profession_comments:
                            values.knowledge_domain_relevant_for_the_profession_comments || '',
                          evidence_of_the_highest_academic_degree_provided:
                            values.evidence_of_the_highest_academic_degree_provided || '',
                          evidence_of_the_highest_academic_degree_provided_comments:
                            values.evidence_of_the_highest_academic_degree_provided_comments || '',
                          evidencing_the_overall_pedagogical_experience:
                            values.evidencing_the_overall_pedagogical_experience || '',
                          evidencing_the_overall_pedagogical_experience_comments:
                            values.evidencing_the_overall_pedagogical_experience_comments || '',
                          are_the_courses_lectured: values.are_the_courses_lectured || '',
                          are_the_courses_lectured_comments:
                            values.are_the_courses_lectured_comments || '',
                          is_the_membership_in_pedagogical_committee_relevant:
                            values.is_the_membership_in_pedagogical_committee_relevant || '',
                          is_the_membership_in_pedagogical_committee_relevant_comments:
                            values.is_the_membership_in_pedagogical_committee_relevant_comments ||
                            '',
                          are_the_trainings_relevant: values.are_the_trainings_relevant || '',
                          are_the_trainings_relevant_comments:
                            values.are_the_trainings_relevant_comments || '',
                          are_the_conferences_relevant: values.are_the_conferences_relevant || '',
                          are_the_conferences_relevant_comments:
                            values.are_the_conferences_relevant_comments || '',
                          are_the_pedagogical_experience_relevant:
                            values.are_the_pedagogical_experience_relevant || '',
                          are_the_pedagogical_experience_relevant_comments:
                            values.are_the_pedagogical_experience_relevant_comments || '',
                          are_the_internship_experience_relevant:
                            values.are_the_internship_experience_relevant || '',
                          are_the_internship_experience_relevant_comments:
                            values.are_the_internship_experience_relevant_comments || '',
                          are_the_membership_in_associations_relevant:
                            values.are_the_membership_in_associations_relevant || '',
                          are_the_membership_in_associations_relevant_comments:
                            values.are_the_membership_in_associations_relevant_comments || '',
                          is_the_research_evidenced_is_relevant:
                            values.is_the_research_evidenced_is_relevant || '',
                          is_the_research_evidenced_is_relevant_comments:
                            values.is_the_research_evidenced_is_relevant_comments || '',
                          are_the_awards_relevant: values.are_the_awards_relevant || '',
                          are_the_awards_relevant_comments:
                            values.are_the_awards_relevant_comments || '',
                          is_the_motivation_relevant: values.is_the_motivation_relevant || '',
                          is_the_motivation_relevant_comments:
                            values.is_the_motivation_relevant_comments || '',
                          innovations_in_engineering_pedagogy_evidenced:
                            values.innovations_in_engineering_pedagogy_evidenced || '',
                          innovations_in_engineering_pedagogy_strengths:
                            values.innovations_in_engineering_pedagogy_strengths || '',
                          innovations_in_engineering_pedagogy_suggestions:
                            values.innovations_in_engineering_pedagogy_suggestions || '',
                          time_management_evidenced: values.time_management_evidenced || '',
                          time_management_evidenced_strengths:
                            values.time_management_evidenced_strengths || '',
                          time_management_evidenced_suggestions:
                            values.time_management_evidenced_suggestions || '',
                          effective_interaction_evidenced:
                            values.effective_interaction_evidenced || '',
                          effective_interaction_evidenced_strengths:
                            values.effective_interaction_evidenced_strengths || '',
                          effective_interaction_evidenced_suggestions:
                            values.effective_interaction_evidenced_suggestions || '',
                          enhancement_of_learning_interactivity:
                            values.enhancement_of_learning_interactivity || '',
                          enhancement_of_learning_interactivity_strengths:
                            values.enhancement_of_learning_interactivity_strengths || '',
                          enhancement_of_learning_interactivity_suggestions:
                            values.enhancement_of_learning_interactivity_suggestions || '',
                          systems_analysis_in_education: values.systems_analysis_in_education || '',
                          systems_analysis_in_education_strengths:
                            values.systems_analysis_in_education_strengths || '',
                          systems_analysis_in_education_suggestions:
                            values.systems_analysis_in_education_suggestions || '',
                          pedagogical_psychology_and_communication:
                            values.pedagogical_psychology_and_communication || '',
                          pedagogical_psychology_and_communication_strengths:
                            values.pedagogical_psychology_and_communication_strengths || '',
                          pedagogical_psychology_and_communication_suggestions:
                            values.pedagogical_psychology_and_communication_suggestions || '',
                          interaction_with_stakeholders: values.interaction_with_stakeholders || '',
                          interaction_with_stakeholders_strengths:
                            values.interaction_with_stakeholders_strengths || '',
                          interaction_with_stakeholders_suggestions:
                            values.interaction_with_stakeholders_suggestions || '',
                          sustainable_development: values.sustainable_development || '',
                          sustainable_development_strengths:
                            values.sustainable_development_strengths || '',
                          sustainable_development_suggestions:
                            values.sustainable_development_suggestions || '',
                          digital_education: values.digital_education || '',
                          digital_education_strengths: values.digital_education_strengths || '',
                          digital_education_suggestions: values.digital_education_suggestions || '',
                          problem_based_project_based_and_practice_oriented_learning:
                            values.problem_based_project_based_and_practice_oriented_learning || '',
                          problem_based_project_based_and_practice_oriented_learning_strengths:
                            values.problem_based_project_based_and_practice_oriented_learning_strengths ||
                            '',
                          problem_based_project_based_and_practice_oriented_learning_suggestions:
                            values.problem_based_project_based_and_practice_oriented_learning_suggestions ||
                            '',
                          learning_outcomes_assessment: values.learning_outcomes_assessment || '',
                          learning_outcomes_assessment_strengths:
                            values.learning_outcomes_assessment_strengths || '',
                          learning_outcomes_assessment_suggestions:
                            values.learning_outcomes_assessment_suggestions || '',
                          course_design: values.course_design || '',
                          course_design_strengths: values.course_design_strengths || '',
                          course_design_suggestions: values.course_design_suggestions || '',
                          engineering_innovation_process:
                            values.engineering_innovation_process || '',
                          engineering_innovation_process_strengths:
                            values.engineering_innovation_process_strengths || '',
                          engineering_innovation_process_suggestions:
                            values.engineering_innovation_process_suggestions || '',
                          lifelong_learning: values.lifelong_learning || '',
                          lifelong_learning_strengths: values.lifelong_learning_strengths || '',
                          lifelong_learning_suggestions: values.lifelong_learning_suggestions || '',
                          facebook_link: values.facebook_link || '',
                          linkedin_link: values.linkedin_link || '',
                          instagram_link: values.instagram_link || '',
                          twitter_link: values.twitter_link || '',
                          personal_website_link: values.personal_website_link || '',
                          comments_to_the_monitoring_committee_chair:
                            values.comments_to_the_monitoring_committee_chair || '',
                        },
                      });
                  } catch (error) {
                    console.log(error);
                  }
                } else {
                  alert('Something went wrong');
                }
                alert('Your changes have been saved');
              };

              return (
                <div key={key} className="fullPersonalPortfolio p-4">
                  {/* //First Form */}
                  <Formik
                    enableReinitialize
                    initialValues={mcmForm}
                    isSubmitting={true}
                    validationSchema={firstFormValidationSchema}
                    onSubmit={saveOnSubmit}
                  >
                    {props => (
                      <PersonalDataForm
                        submitButtonLabel={'Save   * '}
                        mainFormVars={firstFormVars}
                        {...props}
                      />
                    )}
                  </Formik>
                  <p>*click on this {`Save`} button to save your changes made above</p>
                  <br />
                  <br />
                  <br />

                  {/* Second Form */}

                  <Formik
                    enableReinitialize
                    initialValues={mcmForm}
                    isSubmitting={true}
                    validationSchema={secondFormValidationSchema}
                    onSubmit={saveOnSubmit}
                  >
                    {props => (
                      <PersonalDataForm
                        submitButtonLabel={'Save   *'}
                        mainFormVars={secondFormVars}
                        {...props}
                      />
                    )}
                  </Formik>
                  <p>*click on this {`Save`} button to save your changes made above</p>
                </div>
              );
            })}
          </div>
          <br />
          <div className="border shadow border-1  rounded-3 ">
            <h5 className="pt-5 px-5">
              <label htmlFor="has_completed_pdps">
                The applicant has completed accredited PDP? (Yes / No)
              </label>
              <select
                className="form-select form-select-sm my-3"
                onChange={e => {
                  setHasCompletedPdps(e.target.value);
                }}
                name="has_completed_pdps"
              >
                <option value="">Please select</option>
                <option selected={hasCompletedPdps?.includes('Yes')} value="Yes">
                  Yes
                </option>
                <option selected={hasCompletedPdps?.includes('No')} value="No">
                  No
                </option>
              </select>
            </h5>
            {hasCompletedPdps?.includes('Yes') && (
              <h5 className="px-5">
                <label className="w-100 mt-3" htmlFor="has_completed_pdps_comments">
                  Please indicate which or select from database of accredited PDPs:
                  https://enterprof.org/enter-registered-professional-development-programs
                </label>
                <textarea
                  value={hasCompletedPdpsComments}
                  onChange={e => {
                    setHasCompletedPdpsComments(e.target.value);
                  }}
                  className="w-100 mt-2"
                  name="has_completed_pdps_comments"
                  type="text"
                />
              </h5>
            )}
            <h5 className="px-5 pb-5">
              <label className="w-100 mt-3" htmlFor="generalComments">
                General Comments
              </label>
              <textarea
                value={generalComments}
                onChange={e => {
                  setGeneralComments(e.target.value);
                }}
                className="w-100 mt-2"
                name="generalComments"
                type="text"
              />
              <button
                className="btn btn-outline-primary"
                onClick={handleUpdateCustomForm}
                type="button"
              >
                Save
              </button>
            </h5>
          </div>
        </Container>
      )}
    </Layout>
  );
};

export const query = graphql`
  query McmReviewFormPageQueryForEdit($id: String, $lang: String) {
    prismicEditMcmForms(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      type
      url
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        applicant_name
        save_button_label
        title {
          text
        }
      }
    }
    prismicMcmReviewForm(lang: { eq: $lang }) {
      data {
        applicants_portfolio_review
        enter_register_review
        mcm_form_title {
          text
        }
        body {
          ... on PrismicMcmReviewFormDataBodyForm {
            items {
              description
              field_type
              form_type
              name
              placeholder
              required
              radio_options
              title
            }
          }
        }
      }
    }
    prismicMenuLink(lang: { eq: $lang }) {
      ...MenuLinkFragment
    }
  }
`;

export default withPrismicPreview(EditMCMForms, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);
